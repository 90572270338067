@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap);
body,
html {
  margin: 0;
  font-size: 20px;
  letter-spacing: 0.5px;
  font-family: "Maven Pro", sans-serif !important;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body ::selection {
  color: white !important;
  background: #1a91ae !important;
}

.box-shadow-main {
  box-shadow: 0px 6px 16px 0px #0000001a;
}

.cursor-pointer {
  cursor: pointer;
}

.border-1 {
  border: 1px solid #0000001a;
}

.main-heading {
  color: #222;
}
.sub-text {
  color: #22222280;
}

.ml-1 {
  margin-left: 0.25rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.ml-3 {
  margin-left: 1rem !important;
}
.ml-4 {
  margin-left: 2rem !important;
}
.ml-5 {
  margin-left: 3rem !important;
}
.ml-6 {
  margin-left: 4rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}
.pl-2 {
  padding-left: 0.5rem !important;
}
.pl-3 {
  padding-left: 1rem !important;
}
.pl-4 {
  padding-left: 2rem !important;
}
.pl-5 {
  padding-left: 3rem !important;
}
.pl-6 {
  padding-left: 4rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.mr-3 {
  margin-right: 1rem !important;
}
.mr-4 {
  margin-right: 2rem !important;
}
.mr-5 {
  margin-right: 3rem !important;
}
.mr-6 {
  margin-right: 4rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}
.pr-2 {
  padding-right: 0.5rem !important;
}
.pr-3 {
  padding-right: 1rem !important;
}
.pr-4 {
  padding-right: 2rem !important;
}
.pr-5 {
  padding-right: 3rem !important;
}
.pr-6 {
  padding-right: 4rem !important;
}
.d-mobile {
  display: none;
}
.d-mobile-none {
  display: flex;
}
.hotel-information-flex {
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 900px) {
  :root {
    font-size: 14px;
  }
  .d-mobile {
    display: flex;
  }
  .d-mobile-none {
    display: none;
  }
  .hotel-information-flex {
    display: block;
    flex-direction: column;
    align-items: flex-start;
  }
}

